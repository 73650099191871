var alt = require("AltInit");
var clubActions = require("actions/ClubActions");
var PLUtil = require("util/ParseListUtility");
const ConfigKeys = require("ConfigKeys");

class ClubStore {
    constructor() {
        this.displayName = "ClubStore";
        this.clubs = [];
        this.clubDetail = null;
        this.ownClubUser = null;
        this.ownClubUserLoaded = false;
        this.editClubUser = null;
        this.clubUsers = [];
        this.allClubUsers = null;
        this.clubEvents = [];
        this.invalidUser = false;
        this.searchParams = {
            searchText: "",
            sortParams : {
                sortKey: null,
                sortDir: "asc"
            }
        };
        this.eventLoading = true;
        this.isSKillBoardClub = false;
        this.loading = true;
        this.bindListeners({
            showProgress: clubActions.showProgress,
            updateClubDetail: clubActions.updateClubDetail,
            updateClubsList: clubActions.updateClubsList,
            updateClubUsersList: clubActions.updateClubUsersList,
            updateClubEventsList: clubActions.updateClubEventsList,
            updateOwnClubUser: clubActions.updateOwnClubUser,
            addClubUser: clubActions.addClubUser,
            updateClubUser: clubActions.updateClubUser,
            searchClubUser: clubActions.searchClubUser,
            updateSearchParams: clubActions.updateSearchParams,
            updateClubEvent: clubActions.updateClubEvent,
            removeClubEvent: clubActions.removeClubEvent,
            removeClubUser: clubActions.removeClubUser,
            updateEventLoading: clubActions.updateEventLoading,
            checkLoadingNewClub: clubActions.checkLoadingNewClub,
            resetClubDetails: clubActions.resetClubDetails,
            setEditClubUser: clubActions.setEditClubUser,
            setInvalidUser: clubActions.setInvalidUser
        });
    }

    showProgress() {
        this.loading = true;
    }
    updateEventLoading(loading) {
        this.eventLoading = loading;
    }
    checkLoadingNewClub(clubId) {
        if (this.clubDetail != null && this.clubDetail.id !== clubId ) {
            // loaded new club but old details are still available
            this.resetClubDetails()
        }
    }
    updateClubDetail(pClub) {
        this.clubDetail = pClub;
        this.isSKillBoardClub = pClub.id === ConfigKeys.SKillBoardClubId;
        this.checkClubDetailLoadingFinished();
    }
    updateClubsList(pClubsList) {
        this.loading = false;
        this.clubs = pClubsList;
    }
    updateOwnClubUser(pClubUser) {
        this.ownClubUser = pClubUser;
        this.ownClubUserLoaded = true;
    }
    updateClubUsersList(pClubUserList) {
        this.allClubUsers = pClubUserList;
        this.checkClubDetailLoadingFinished();
        this.filterObjects();

    }
    updateClubEventsList(pClubEventList) {
        this.eventLoading = false;
        this.clubEvents = pClubEventList;
    }
    updateClubEvent(clubEvent) {
        this.updateClubEventsList(PLUtil.updateOrPush(this.clubEvents, clubEvent));
    }

    removeClubEvent(clubEvent) {
        this.updateClubEventsList(
            this.clubEvents.filter(function (u) {
                return u.id != clubEvent.id;
            })
        );
    }
    setEditClubUser(clubUser) {
        this.editClubUser = clubUser;
    }
    addClubUser(clubUser) {
        this.clubUsers.push(clubUser);
        if (this.clubUsers.length > this.allClubUsers.length) {
            this.allClubUsers.push(clubUser);
        }
    }

    searchClubUser(pSearchParams) {
        this.updateSearchParams(pSearchParams);
        this.filterObjects();
    }

    updateSearchParams(params) {
        this.searchParams = params;
    }

    filterObjects() {
        let foundClubUser;
        // has text to search for
        if (this.searchParams.searchText.length > 0) {
            var that = this;
            var searchString = this.searchParams.searchText.toLowerCase();
            foundClubUser = this.allClubUsers.filter(u => {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            foundClubUser = this.allClubUsers;
        }
        // group filter
        if (this.searchParams.searchGroup != null) {
            foundClubUser = foundClubUser.filter(cu => {
                return cu.hasGroup(this.searchParams.searchGroup) || cu.isArticle(this.searchParams.searchGroup);
            });
        }
        // function filter
        if (this.searchParams.searchFunct != null) {
            foundClubUser = foundClubUser.filter(cu => {
                return cu.hasFunction(this.searchParams.searchFunct) || cu.getRole() === this.searchParams.searchFunct;
            });
        }
        this.clubUsers = PLUtil.sortList(foundClubUser, this.searchParams.sortParams);
    }

    updateClubUser(clubUser) {
        this.updateClubUsersList(PLUtil.updateOrPush(this.allClubUsers, clubUser));
    }
    removeClubUser(clubUser) {
        this.updateClubUsersList(
            this.allClubUsers.filter(function (u) {
                return u.id != clubUser.id;
            })
        );
    }

    checkClubDetailLoadingFinished() {
        if (this.clubDetail != null && this.allClubUsers != null) {
            this.loading = false;
        }
    }


    setInvalidUser() {
        this.clubDetail = null;
        this.isSKillBoardClub = false;
        this.clubUsers = [];
        this.allClubUsers = [];
        this.loading = false;
        this.invalidUser = true;
    }

    resetClubDetails() {
        this.loading = true;
        this.clubDetail = null;
        this.ownClubUser = null;
        this.ownClubUserLoaded = false;
        this.editClubUser = null;
        this.clubUsers = [];
        this.allClubUsers = null;
        this.clubEvents = [];
        this.isSKillBoardClub = false;
    }

}
module.exports = alt.createStore(ClubStore);
