const Parse = require("ParseInit");
const Utility = require("util/Utility");

const TournamentUser = Parse.Object.extend("TournamentUser", {
    col: {
        objectId: "objectId",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        editor: "editor",
        killCounts: "killCounts",
        killValue: "killValue",
        finalKillCounts: "finalkillCounts",
        finalKillValue: "finalkillValue",
        licenseNumber: "licenseNumber",
        licenseUnionCode: "licenseUnionCode",
        name: "name",
        surname: "surname",
        note: "note",
        paid: "paid",
        payAmount: "payAmount",
        payDate: "payDate",
        payType: "payType",
        equipCheckDate: "equipCheckDate",
        playerEmail: "playerEmail",
        resultPosition: "resultPosition",
        finalResultPosition: "finalresultPosition",
        sex: "sex",
        sortUnion: "sortUnion",
        status: "status",
        option: "option",
        tRegSlotID: "tRegSlotID",
        cupPoints: "cupPoints",
        cupGroup: "cupGroup",
        allCupPoints: "allCupPoints",
        sumPoints: "sumPoints",
        finalSumPoints: "finalsumPoints",
        tournamentConfigAge: "tournamentConfigAge",
        tournamentConfigBow: "tournamentConfigBow",
        union: "union",
        payRefID: "payRefID",
        extRefID: "extRefID",
        parcoursID: "parcoursID",
        tournamentGroupID: "tournamentGroupID",
        tournamentID: "tournamentID",
        tournamentRoundUserIDs: "tournamentRoundUserIDs",
        transactionID: "transactionID",
        finalTournamentRoundUserIDs: "finaltournamentRoundUserIDs",
        calcTournamentRoundUserIDs: "calcTournamentRoundUserIDs",
        tournamentStringID: "tournamentStringID",
        challengeDetail: "challengeDetail",
        userDetailID: "userDetailID",
        userID: "userID"
    },
    status: {
        registered: "R",
        present: "A",
        started: "S",
        disqualified: "X",
        scoreSubmitted: "L",
        finished: "C",
        finishedRound: "F", // finished actual Round - result confirmed with at least two result
        proofSingleResult: "P", // finished actual Round - but only one result
        deleted: "D",
        waiting: "W" //wait for next round -last one finished
    },
    challengeDetailKeys : {
        contenderMail: "ContMail",
        contenderName: "ContName",
        contenderMessage: "ContMsg",
        competitorMail: "CompMail",
        competitorName: "CompName",
        competitorMessage: "CompMsg"
    },
    payTypes: {
        sofort: "SOFORT",
        paypal: "PAYPAL",
        visa: "VISA",
        master: "MASTER"
    },
    //
    // custom
    //
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isPresent() {
        return this.status.present === this.getStatus();
    },
    isRegistered() {
        return this.status.registered === this.getStatus();
    },
    isDisqualified() {
        return this.status.disqualified === this.getStatus();
    },
    isFinished() {
        return this.status.finished === this.getStatus()
            || this.status.finishedRound === this.getStatus()
            || this.status.proofSingleResult === this.getStatus();
    },
    getLabels() {
        return [this.getFullName(), this.getPlayerEmail(), this.getUnion(), this.getLicenseNumber(), this.getGroupColText(), this.getPayRefID()].join(" ");
    },
    getDatalistText() {
        let text = this.getFullName() + " (" + this.getPlayerEmail()+ ")";
        if (this.getUnion() != null) {
            text += ", " + this.getUnion()
        }
        if (this.getLicenseUnionCode() && this.getLicenseNumber()) {
            text += ", " + this.getLicenseUnionCode() + "#: " + this.getLicenseNumber();
        }
        return text;
    },
    getDatalistTextNoMail() {
        let text = this.getFullName();
        if (this.getUnion() != null) {
            text += ", " + this.getUnion()
        }
        if (this.getLicenseUnionCode() && this.getLicenseNumber()) {
            text += ", " + this.getLicenseUnionCode() + "#: " + this.getLicenseNumber();
        }
        return text;
    },
    getDatalistImage() {
        return null;
    },
    getSortColumn(colName) {
        if (colName === "group") {
            return this.getGroupColText()
        }
        return this.get(colName)
    },
    isSelfRegisteredUsers(user) {
        if (user != null) {
            if (this.getUserID() && this.getUserID().id == user.id) {
                return true;
            } else if (this.getEditor() && user.id == this.getEditor().id ) {
                // was edited by logged in user
                return true;
            } else if (user.getEmail() == this.getPlayerEmail()) {
                // same email - assume same person
                return true;
            }
        }
        return false;
    },
    //
    // getter/setter
    //
    getEditor() {
        return this.get(this.col.editor)
    },
    setEditor(editor) {
        return this.set(this.col.editor, editor)
    },
    getExtRefID() {
        return this.get(this.col.extRefID)
    },
    setExtRefID(extRefID) {
        return this.set(this.col.extRefID, extRefID)
    },
    getShootCounter() {
        let counter = 0;
        var killCountsStr = this.getKillCounts();
        if (killCountsStr) {
            var killCounts = killCountsStr.split(",");
            killCounts.forEach(function (killCountString) {
                var killCnt = killCountString.split(':');
                if (killCnt.length > 1) {
                    // found count
                    counter += parseInt(killCnt[1]);

                }
            });
        }
        return counter;
    },
    getUserDictShootCounter(isFinal, addFinale) {
        let counter = 0;
        var killCountsStr = this.getUserDictKillCounts(isFinal, addFinale);
        if (killCountsStr) {
            var killCounts = killCountsStr.split(",");
            killCounts.forEach(function (killCountString) {
                var killCnt = killCountString.split(':');
                if (killCnt.length > 1) {
                    // found count
                    counter += parseInt(killCnt[1]);

                }
            });
        }
        return counter;
    },
    getKillCounts() {
        return this.get(this.col.killCounts)
    },
    setKillCounts(killCounts) {
        return this.set(this.col.killCounts, killCounts)
    },
    getFinalKillCounts() {
        return this.get(this.col.finalKillCounts)
    },
    getUserDictKillCounts(isFinal, addFinale) {
        if (addFinale) {
            var resKillCounts = this.getKillCounts();
            if (resKillCounts == null) {
                return this.getFinalKillCounts();
            } else {
                var finalKillCounts = this.getFinalKillCounts();
                if (finalKillCounts == null) {
                    return this.getKillCounts();
                } else {
                    // build new array
                    var nKillCounts = {};
                    var killCountsstr ="";
                    this.buildKillCountDict(nKillCounts, resKillCounts);
                    this.buildKillCountDict(nKillCounts, finalKillCounts);
                    Object.keys(nKillCounts).map(i => {
                        killCountsstr += (i + ":" + nKillCounts[i] + ",");
                    });
                    return killCountsstr;
                }
            }
        } else {
            if (isFinal) {
                return this.getFinalKillCounts();
            } else {
                return this.getKillCounts();
            }
        }
    },
    getKillCounts2String() {
        return Utility.buildKillCounts2String(this.getKillCounts());
    },
    getFinalKillCounts2String() {
        return Utility.buildKillCounts2String(this.getFinalKillCounts());
    },
    getHighestZoneCount() {
        return Utility.highestZoneFromKillCount(this.getKillCounts());
    },
    getFinalAddKillCount2String() {
        var resKillCounts = this.getKillCounts();
        if (resKillCounts == null) {
            return this.getFinalKillCounts2String();
        } else {
            var finalKillCounts = this.getFinalKillCounts();
            if (finalKillCounts == null) {
                return this.getKillCounts2String();
            } else {
                // build new array
                var nKillCounts = {};
                var killCountsstr ="";
                this.buildKillCountDict(nKillCounts, resKillCounts);
                this.buildKillCountDict(nKillCounts, finalKillCounts);
                Object.keys(nKillCounts).map(i => {
                    killCountsstr += (i + ":" + nKillCounts[i] + ",");
                });
                return Utility.buildKillCounts2String(killCountsstr.slice(0, -1));
            }
        }
    },
    buildKillCountDict(nKillCounts, resKillCounts) {
        var killCountsstr = "t,";
        var rkillCounts = resKillCounts.split(",");
        for (var k = 0; k < rkillCounts.length; k++) {
            if (k == 0) {
                killCountsstr = rkillCounts[k] + ",";
            } else {
                var killCnt = rkillCounts[k].split(":");
                if (killCnt.length = 2) {
                    var amount = nKillCounts[killCnt[0]];
                    if (amount) {
                        amount = parseInt(amount) + parseInt(killCnt[1]);
                    } else {
                        amount = parseInt(killCnt[1]);
                    }
                    nKillCounts[killCnt[0]] = amount;
                }
            }
        }
    },
    getKillValue() {
        return this.get(this.col.killValue)
    },
    setKillValue(killValue) {
        return this.set(this.col.killValue, killValue)
    },
    getFinalKillValue() {
        return this.get(this.col.finalKillValue)
    },
    setFinalKillValue(killValue) {
        return this.set(this.col.finalKillValue, killValue)
    },
    getUserDictKillValue(isFinal, addFinale) {
        if (addFinale) {
            return this.getKillValue() + this.getFinalKillValue();
        } else {
            if (isFinal) {
                return this.getFinalKillValue();
            } else {
                return this.getKillValue();
            }
        }
    },
    getLicenseNumber() {
        return this.get(this.col.licenseNumber)
    },
    setLicenseNumber(licenseNumber) {
        if (licenseNumber != null && licenseNumber.length > 0) {
            return this.set(this.col.licenseNumber, licenseNumber)
        } else {
            return this.unset(this.col.licenseNumber)
        }
    },
    getLicenseUnionCode() {
        return this.get(this.col.licenseUnionCode)
    },
    setLicenseUnionCode(code) {
        return this.set(this.col.licenseUnionCode, code)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    setSurname(surname) {
        return this.set(this.col.surname, surname)
    },
    getSurname() {
        return this.get(this.col.surname)
    },
    getFullName() {
        var surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return  this.getName() + " " + surname;
        } else {
            return  this.getName();
        }
    },
    setFullName(name, surname) {
        this.set(this.col.name, name);
        this.set(this.col.surname, surname);
    },
    getNote() {
        return this.get(this.col.note)
    },
    setNote(note) {
        return this.set(this.col.note, note)
    },
    getTournamentRegSlotID() {
        return this.get(this.col.tRegSlotID)
    },
    getTournamentRegSlotName() {
        const tRegSlot = this.getTournamentRegSlotID();
        if (tRegSlot != null) {
            return tRegSlot.getName();
        }
        return null
    },
    setTournamentRegSlotID(tRegSlotID) {
        return this.set(this.col.tRegSlotID, tRegSlotID)
    },
    getOption() {
        return this.get(this.col.option)
    },
    setOption(option) {
        if (option != null && option.length > 0) {
            return this.set(this.col.option, option)
        } else {
            return this.unset(this.col.option)
        }
    },
    getPaid() {
        return this.get(this.col.paid)
    },
    getPaidIcon() {
        if (this.isPaid()) {
            const payType = this.getPayType();
            if (payType === this.payTypes.sofort) {
                return ["fab","stripe-s"]
            } else if (payType === this.payTypes.paypal) {
                return ["fab","paypal"]
            } else if (payType === this.payTypes.visa) {
                return "credit-card"
            } else if (payType === this.payTypes.master) {
                return "credit-card"
            } else {
                return "money-bill-wave"
            }
        }
        return ["fab","creative-commons-nc-eu"];
    },
    isPaid() {
        return this.getPaid() == true;
    },
    setPaid(paid) {
        return this.set(this.col.paid, paid)
    },
    setPayment(hasPaid) {
        if (hasPaid) {
            this.setPayDate(new Date())
        } else {
            this.unset(this.col.payDate);
            this.unset(this.col.payType);
        }
        this.setPaid(hasPaid)
    },
    isEquipChecked() {
        return this.getEquipCheckedDate() != null;
    },
    setEquipChecked(checked) {
        if (checked) {
            this.set(this.col.equipCheckDate, new Date())
        } else {
            this.unset(this.col.equipCheckDate)
        }
    },
    getEquipCheckedDate() {
        return this.get(this.col.equipCheckDate)
    },
    getParcoursID() {
        return this.get(this.col.parcoursID)
    },
    setParcoursID(parcoursID) {
        return this.set(this.col.parcoursID, parcoursID)
    },
    getPayAmount() {
        return this.get(this.col.payAmount)
    },
    setPayAmount(payAmount) {
        return this.set(this.col.payAmount, payAmount)
    },
    getReadablePayDate: function () {
        const date = this.getPayDate()
        if (date) {
            return Utility.getReadableDate(date, true)
        }
        return "x";
    },
    getPayDate() {
        return this.get(this.col.payDate)
    },
    setPayDate(payDate) {
        return this.set(this.col.payDate, payDate)
    },
    getPayRefID() {
        return this.get(this.col.payRefID)
    },
    setPayRefID(payRefID) {
        return this.set(this.col.payRefID, payRefID)
    },
    getPayType() {
        return this.get(this.col.payType)
    },
    setPayType(payType) {
        return this.set(this.col.payType, payType)
    },
    getPlayerEmail() {
        return this.get(this.col.playerEmail)
    },
    setPlayerEmail(playerEmail) {
        return this.set(this.col.playerEmail, playerEmail)
    },
    hasValidPlayerEmail() {
        return Utility.isValidEmail(this.getPlayerEmail());
    },
    getResultPositionForCupSorting() {
        const resultPosition = this.getResultPosition()
        if (resultPosition > 1) {
            return 1
        }
        // return 0 instead of 1 for first position to ensure more wins has higher rating
        return 0
    },
    getResultPosition() {
        return this.get(this.col.resultPosition)
    },
    setResultPosition(resultPosition) {
        return this.set(this.col.resultPosition, resultPosition)
    },
    getFinalResultPosition() {
        return this.get(this.col.finalResultPosition);
    },
    getSexIcon() {
        if (this.getSex() === 0) {
            return "mars"
        }
        return "venus";
    },
    getSex() {
        return this.get(this.col.sex)
    },
    setSex(sex) {
        return this.set(this.col.sex, sex)
    },
    buildSortableUnion: function () {
        let tmpUnion = this.getUnion()
        if (tmpUnion != null && tmpUnion.length > 0) {
            // remove blanks and so own
            tmpUnion = tmpUnion.toLowerCase();
            tmpUnion = tmpUnion.replace(/-/g,'');
            tmpUnion = tmpUnion.replace(/ /g,'');
        } else {
            tmpUnion = "zzzzz";
        }
        return tmpUnion
    },
    getSortUnion() {
        let sortUnion = this.get(this.col.sortUnion)
        if (sortUnion == null) {
            // build sortUnion
            sortUnion = this.buildSortableUnion();
            this.setSortUnion(sortUnion)
        }
        return sortUnion
    },
    setSortUnion(sortUnion) {
        return this.set(this.col.sortUnion, sortUnion)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getCupPoints() {
        let points = this.get(this.col.cupPoints);
        if (!points || this.status.disqualified === this.getStatus()) {
            // disqualified
            points = 0
        }
        return points;
    },
    getCupPointString() {
        let number = this.getCupPoints();
        if (number < 10) {
            return "0" + number;
        } else {
            return number.toString();
        }
    },
    setCupPoints(cupPoints) {
        return this.set(this.col.cupPoints, cupPoints)
    },
    getAllCupPoints() {
        return this.get(this.col.allCupPoints);
    },
    setAllCupPoints(cupPointsArray) {
        return this.set(this.col.allCupPoints, cupPointsArray)
    },
    getCupGroup() {
        return this.get(this.col.cupGroup)
    },
    setCupGroup(cupGroup) {
        return this.set(this.col.cupGroup, cupGroup)
    },
    getSumPoints() {
        let points = this.get(this.col.sumPoints);
        if (!points || this.status.disqualified === this.getStatus()) {
            // disqualified
            points = "---"
        }
        return points;
    },
    getSortableSumPoints() {
        let points = this.get(this.col.sumPoints);
        if (!points || this.status.disqualified === this.getStatus()) {
            // disqualified
            points = 0;
        }
        return points;
    },
    setSumPoints(sumPoints) {
        return this.set(this.col.sumPoints, sumPoints)
    },
    getFinalSumPoints() {
        let points = this.get(this.col.finalSumPoints);
        if (!points || this.status.disqualified === this.getStatus()) {
            // disqualified
            points = "---"
        }
        return points;
    },
    getFinalSortableSumPoints() {
        let points = this.get(this.col.finalSumPoints);
        if (!points || this.status.disqualified === this.getStatus()) {
            // disqualified
            points = 0;
        }
        return points;
    },
    getUserDictSortSum(isFinal, addFinale) {
        if (addFinale) {
            return this.getSortableSumPoints() + this.getFinalSortableSumPoints();
        } else {
            if (isFinal) {
                return this.getFinalSortableSumPoints();
            } else {
                return this.getSortableSumPoints();
            }
        }
    },
    getTUserDPS() {
        const sumPoints = this.getSumPoints();
        const shootCounter = this.getShootCounter();
        if (shootCounter > 0 && sumPoints > 0 ) {
            let dps = sumPoints / shootCounter;
            dps = +dps.toFixed(2); // needed to return as number
            return dps;
        } else {
            return 0;
        }
    },
    getUserDictTUserDPS(isFinal, addFinale) {
        const sumPoints = this.getUserDictSortSum(isFinal, addFinale);
        const shootCounter = this.getUserDictShootCounter(isFinal, addFinale);
        if (shootCounter > 0 && sumPoints > 0 ) {
            let dps = sumPoints / shootCounter;
            dps = +dps.toFixed(2); // needed to return as number
            return dps;
        } else {
            return 0;
        }
    },
    getTournamentConfigAge() {
        return this.get(this.col.tournamentConfigAge)
    },
    getTournamentConfigAgeCodeName() {
        const tConfig = this.getTournamentConfigAge();
        if (tConfig != null) {
            return tConfig.getCodeName();
        }
        return "";
    },
    getTournamentConfigAgeCode() {
        const tConfig = this.getTournamentConfigAge();
        if (tConfig != null) {
            return tConfig.getCodeOrShortName();
        }
        return "";
    },
    setTournamentConfigAge(tournamentConfigAge) {
        return this.set(this.col.tournamentConfigAge, tournamentConfigAge)
    },
    getTournamentConfigBow() {
        return this.get(this.col.tournamentConfigBow)
    },
    getTournamentConfigBowCodeName() {
        const tConfig = this.getTournamentConfigBow();
        if (tConfig != null) {
            return tConfig.getCodeName();
        }
        return "";
    },
    getTournamentConfigBowCode() {
        const tConfig = this.getTournamentConfigBow();
        if (tConfig != null) {
            return tConfig.getCodeOrShortName();
        }
        return "";
    },
    setTournamentConfigBow(tournamentConfigBow) {
        return this.set(this.col.tournamentConfigBow, tournamentConfigBow)
    },
    getTournamentGroupID() {
        return this.get(this.col.tournamentGroupID)
    },
    setTournamentGroupID(tournamentGroupID) {
        return this.set(this.col.tournamentGroupID, tournamentGroupID)
    },
    getGroupColText() {
        if (this.getTournamentGroupID()) {
            return this.getTournamentGroupID().getGroupColText();
        }
        return "SP:X";
    },
    getGroupKeyForUser() {
        if (this.getTournamentGroupID()) {
            return this.getTournamentGroupID().getGroupKeyForUser(this.id);
        }
        return "";
    },

    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    getTournamentRoundUserIDs() {
        return this.get(this.col.tournamentRoundUserIDs);
    },
    getFinalTournamentRoundUserIDs() {
        return this.get(this.col.finalTournamentRoundUserIDs);
    },
    getCalcTournamentRoundUserIDs() {
        return this.get(this.col.calcTournamentRoundUserIDs);
    },
    setCalcTournamentRoundUserIDs(tRoundUserIDs) {
        return this.set(this.col.calcTournamentRoundUserIDs, tRoundUserIDs );
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getTournamentStringID() {
        return this.get(this.col.tournamentStringID)
    },
    setTournamentStringID(tournamentStringID) {
        return this.set(this.col.tournamentStringID, tournamentStringID)
    },
    getTransactionID() {
        return this.get(this.col.transactionID)
    },
    getUnion() {
        return this.get(this.col.union)
    },
    setUnion(union) {
        if (union != null && union.length > 0) {
            return this.set(this.col.union, union)
        } else {
            return this.unset(this.col.union)
        }
    },
    getUserDetailID() {
        return this.get(this.col.userDetailID)
    },
    setUserDetailID(userDetailID) {
        return this.set(this.col.userDetailID, userDetailID)
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getChallengeDetail() {
        return this.get(this.col.challengeDetail);
    },
    setChallengeDetail(challengeDetail) {
        this.set(this.col.challengeDetail, challengeDetail);
    },
    getChallengeContenderName() {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject) {
            var challengerName = challengeObject[this.challengeDetailKeys.contenderName];
            if (challengerName) {
                return challengerName;
            }
        }
        return null;
    },
    getChallengeCompetitorName() {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject) {
            var competitorName = challengeObject[this.challengeDetailKeys.competitorName];
            if (competitorName) {
                return competitorName;
            }
        }
        return null;
    },
    getChallengeContenderMail() {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject) {
            var challengerName = challengeObject[this.challengeDetailKeys.contenderMail];
            if (challengerName) {
                return challengerName;
            }
        }
        return null;
    },
    getChallengeCompetitorMail() {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject) {
            var competitorName = challengeObject[this.challengeDetailKeys.competitorMail];
            if (competitorName) {
                return competitorName;
            }
        }
        return null;
    },
    getChallengeContenderMessage() {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject) {
            var message = challengeObject[this.challengeDetailKeys.contenderMessage];
            if (message) {
                return message;
            }
        }
        return "";
    },
    getChallengeCompetitorMessage() {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject) {
            var message = challengeObject[this.challengeDetailKeys.competitorMessage];
            if (message) {
                return message;
            }
        }
        return "";
    },
    setContender(name, email, message){
        var challengeObject = this.getChallengeDetail();
        if (challengeObject == null) {
            challengeObject = {};
        }
        challengeObject[this.challengeDetailKeys.contenderName] = name;
        challengeObject[this.challengeDetailKeys.contenderMail] = email;
        if (message != null && message.length > 0) {
            challengeObject[this.challengeDetailKeys.contenderMessage] = message;
        }
        this.setChallengeDetail(challengeObject);
    },
    setCompetitor(name, email, message) {
        var challengeObject = this.getChallengeDetail();
        if (challengeObject == null) {
            challengeObject = {};
        }
        challengeObject[this.challengeDetailKeys.competitorName] = name;
        challengeObject[this.challengeDetailKeys.competitorMail] = email;
        if (message != null && message.length > 0) {
            challengeObject[this.challengeDetailKeys.competitorMessage] = message;
        }
        this.setChallengeDetail(challengeObject);
    },
    removeCompetitor(){
        var challengeObject = this.getChallengeDetail();
        if (challengeObject != null) {
            delete challengeObject[this.challengeDetailKeys.competitorName];
            delete challengeObject[this.challengeDetailKeys.competitorMail];
            delete challengeObject[this.challengeDetailKeys.competitorMessage];
        }
        this.setChallengeDetail(challengeObject);
    },
    removeContender(){
        var challengeObject = this.getChallengeDetail();
        if (challengeObject != null) {
            delete challengeObject[this.challengeDetailKeys.contenderName];
            delete challengeObject[this.challengeDetailKeys.contenderMail];
            delete challengeObject[this.challengeDetailKeys.contenderMessage];
        }
        this.setChallengeDetail(challengeObject);
    },
    // {
    //   - mandatory
    //     "surname": "Parschisek",
    //     "name": "Alexander",
    //     "sex": "0",
    //     "mail": "alex@freespirits.at",
    //     "union": "BC OBERSCHÜTZEN",
    //   - optional
    //     "option": "Burgenland"
    //     "licenseNumber": "10100"
    //     "licenseUnionCode": "HST"
    //     "note": "Bemerkungen"
    // }
    buildTournamentUser(tournament, user, valueDict, selectedBowType, selectedAgeType) {
        const tournamentUser = new TournamentUser();
        // tournament values
        tournamentUser.setParcoursID(tournament.getParcoursID());
        tournamentUser.setTournamentID(tournament);
        tournamentUser.setTournamentStringID(tournament.id);
        tournamentUser.setStatus(this.status.registered);
        tournamentUser.setEditor(user);
        // form values
        tournamentUser.setFullName(valueDict.name, valueDict.surname);
        tournamentUser.setPlayerEmail(valueDict.mail);
        tournamentUser.setSex(parseInt(valueDict.sex));
        tournamentUser.setTournamentConfigBow(selectedBowType);
        tournamentUser.setTournamentConfigAge(selectedAgeType);
        tournamentUser.setPayAmount(valueDict.price);
        tournamentUser.setPayRefID(new Date().getTime().toString());
        if (valueDict.option != null) {
            tournamentUser.setOption(valueDict.option);
        }
        if (valueDict.union != null) {
            tournamentUser.setUnion(valueDict.union);
        }
        if (valueDict.licenseNumber != null) {
            tournamentUser.setLicenseNumber(valueDict.licenseNumber);
        }
        if (valueDict.licenseUnionCode != null) {
            tournamentUser.setLicenseUnionCode(valueDict.licenseUnionCode);
        }
        if (valueDict.note != null) {
            tournamentUser.setNote(valueDict.note);
        }
        return tournamentUser;
    }
});

module.exports = TournamentUser;
