const alt = require("AltInit");
const parseDao = require("actdao/ParseBaseDao");
const userDao = require("actdao/UserDao");
const notificationActions = require("components/notification/NotificationActions");

const {User, UserToken} = require("parse/_Domain");

class UserInfoActions {

    saveUser(user, params, callback) {
        userDao.editUserCCP(params)
            .then(result => {
                notificationActions.success("modal.login.save.success");
                callback({reloadUser:true})
            })
            .catch(error => {
                error.trace = "USRA.saveUser";
                notificationActions.parseError(error);
            });
        return {};
    };

    createEmptyUserDetail(user, userDetail, callback) {
        if (user) {
            if (userDetail != null) {
                userDao.createEmptyUserAddress(user, userDetail)
                    .then(usrDetail => {
                        callback({usrDetail: usrDetail})
                    })
                    .catch(error => {
                        error.trace = "USRIA.createEmptyUserAddress";
                        notificationActions.parseError(error);
                    });
            } else {
                // create userDetail and address
                userDao.createEmptyUserDetail(user)
                    .then(usrDetail => {
                        user.set("userDetailID", usrDetail);
                        parseDao.saveObject(user)
                            .then(sUser => {
                                callback({usrDetail: usrDetail, user: sUser})
                            })
                            .catch(error => {
                                error.trace = "USRIA.createEmptyUserDetail.saveObject";
                                notificationActions.parseError(error);
                            });
                    })
                    .catch(error => {
                        error.trace = "USRIA.createEmptyUserDetail";
                        notificationActions.parseError(error);
                    });
            }
        }
        return {};
    }

    renameUserDetail(usrDetail, name, surname, callback) {
        usrDetail.setFullName(name, surname);
        parseDao.saveObject(usrDetail)
            .then(sUsrDetail => {
                callback({usrDetail: sUsrDetail})
            })
            .catch(error => {
                error.trace = "USRIA.renameUserDetail.saveObject";
                notificationActions.parseError(error);
            });
        return {};
    }
}

module.exports = alt.createActions(UserInfoActions);
